import React, { FC, useEffect, useState } from 'react';

interface ITaskLoadingProps {
    show: boolean; // namespace
    text: string;
    delay: number;
    switchShow: (key: any) => void;
}

const Toast: FC<ITaskLoadingProps> = ({ show, text, delay,switchShow }) => {
    useEffect(() => {
        if (show) {
            console.log(delay);
            setTimeout(() => {
                switchShow(false);
            }, delay);
        }
    }, [show]);

    return (
        <div className="popbox" style={{ display: show ? 'block' : 'none' }}>
            <div className="loading">
                {text}
            </div>
        </div>
    );
}

export default Toast;
