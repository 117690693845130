
import axios from 'axios';

const codeMessage: { [key: string]: any } = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};

const service = axios.create({
    baseURL: "/api",
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        //'Authorization': getToken()
    },
});

service.interceptors.request.use(
    config => {
        // Do something before request is sent
        const token = localStorage.getItem("Authorization") || '';
        if (!token) {
            // return config;
        }
        config.headers.Authorization = token;

        // const { url } = config
        // if (/^\/api\//.test(url) && !token && !window.location.href.indexOf('user') > -1) {
        //     router.history.push('/user/login'); // 跳转到登录页
        // }
        return config
    },
    error => {
        console.log(error);
        // Do something with request error
        return Promise.reject(error);
    }
);

// respone拦截器
service.interceptors.response.use(
    response => {
        if (response.status !== 200) {
            return {
                status: response.status,
                data: null,
                msg: '发生错误'
            };
        } else {
            const res = response.data;
            if (res.status === 401) {
                // history.push('/login');
            }
            return response.data;
        }
    },
    error => {
        if (error === undefined || error.code === 'ECONNABORTED') {
            return Promise.reject(error);
        }
        const { response: { status, statusText, data: { msg = '服务器发生错误' } } } = error
        const { response } = error
        const text = codeMessage[status] || statusText || msg

        if (status === 500 || status === 400) {
            return {
                status: response.status,
                data: null,
                msg: text
            };
        }

        return Promise.reject(error)
    }
);

export default service;
