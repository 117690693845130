import React, { FC, useState } from 'react';

interface ITaskLoadingProps {
    show: boolean; // namespace
    text: string;
}

const Loading: FC<ITaskLoadingProps> = ({ show, text }) => {
    return (
        <div className="popbox" style={{ display: show ? 'block' : 'none' }}>
            <div className="loading">
                <div className="loader"></div>
                {text}
            </div>
        </div>
    );
}

export default Loading;
