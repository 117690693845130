import React, { FC, useState } from 'react';

interface ITaskOfflineProps {
    text: string;
    show: boolean; // namespace
    switchShow: (key: any) => void;
}

const TaskInvOffline: FC<ITaskOfflineProps> = ({ show, switchShow, text }) => {
    return (
        <div className="popbox" style={{ display: show ? 'block' : 'none' }}>
            <div className="wrapper" style={{ height: '320px' }}>
                <div className="top3">活动下线通知</div>
                <div className="content" style={{ height: '200px' }}>
                    <div style={{ lineHeight: '22px', margin: '0 0 20px 0', fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: text }}></div>
                    <button type="button" className="btnpost" onClick={switchShow}>我知道了</button>
                </div>
            </div>
        </div>
    );
}

export default TaskInvOffline;
