import React, { FC, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getActiveInfo, getTrack, getWithdrawList, postVerCode, register, isInviteeRegister, TrackType } from '../services/invite';
import { IDownLoadWrapper, IWidthdrawItem } from '../models/shareapp';
import Loading from './loading';
import Toast from './toast';
import { RedPacketCoundown } from '../components/widget';
import { Carousel } from '../components/carousel';

export interface IChannelUserIdByDwn {
    cid: string;
    arg: string;
    version?: string;
    deviceId?: string;
    // invitee?: string;
}

const TaskDownload = (props: any) => {
    const initInfo: IDownLoadWrapper = { endTime: '', isRegisterInvitee: false, config: {} }
    const [downloadInfo, setDownloadInfo] = useState(initInfo);
    const params: IChannelUserIdByDwn = props.match.params;

    const initWithdraws: IWidthdrawItem[] = [];
    const [withdrawList, setWithdrawList] = useState(initWithdraws);

    const [isLoading, setIsLoading] = useState(false);
    // const [loadingError, setLoadingError] = useState('');

    // const [phone, setPhone] = useState('');
    // const [verCode, setVerCode] = useState('');

    // const [phoneError, setPhoneError] = useState('');
    // const [verCodeError, setVerCodeError] = useState('');
    // const [registerError, setRegisterError] = useState('');

    // const [verCodeDisabled, setVerCodeDisabled] = useState(false);
    const [countDown, setCountDown] = useState(0);
    const [loadingText, setLoadingText] = useState('数据加载中');
    // const [isShowToast, setIsShowToast] = useState(false);
    const [toast, setToast] = useState({ show: false, text: '' });
    // const [toastText, setToastText] = useState('');

    let vcodeTimer: any;

    const isWx = navigator.userAgent.indexOf('MicroMessenger') !== -1;
    const [showTips, setShowTips] = useState(false);

    function track(trackType: TrackType, lbl: string) {
        getTrack({
            userId: params.arg ? params.arg : '',
            channelId: params.cid,
            inviterId: params.deviceId,
            trackType: trackType,//isWx ? 'DownloadIndex_Wx' : 'DownloadIndex',
            page: 'download',
            label: lbl,
            appVersion: params.version
        });
    }

    // function vcodeInterval() {
    //     vcodeTimer = setInterval(() => {
    //         if (countDown > 0) {
    //             setCountDown(countDown - 1);
    //             // setVerCodeDisabled(false);
    //         }
    //     }, 1000);
    // }

    function switchShowToast(e: boolean) {
        setToast({ show: e, text: '' });
    }

    useEffect(() => {
        setIsLoading(true);

        getActiveInfo({ id: params.arg, cid: params.cid, inviteeId: '' }).then(t => {
            setIsLoading(false);
            if (t.status === 200) {
                setDownloadInfo(t.data);
            } else {
                setToast({ show: true, text: t.msg });
            }
        });
        getWithdrawList({ cid: params.cid, pageIndex: 0, pageSize: 5 }).then(t => {
            setWithdrawList(t.data);
        });

        const dwnIndexKey = "trackdownloadindex";
        if (params.deviceId && params.deviceId.length > 5) {
            // 用户第二次打开不计数
            if (localStorage.getItem(dwnIndexKey) !== params.deviceId) {
                track(isWx ? 'InvitePage_Load_Wechat' : 'InvitePage_Load_Browser', '被邀请人页面加载');
                localStorage.setItem(dwnIndexKey, params.deviceId);
            } else {
                track(isWx ? 'InvitePage_Load_Wechat' : 'InvitePage_Load_Browser', '被邀请人页面加载_重复');
            }
        } else {
            track(isWx ? 'InvitePage_Load_Wechat' : 'InvitePage_Load_Browser', '被邀请人页面加载_OAID为空');
        }

    }, []);

    // useEffect(() => {
    //     vcodeInterval();
    //     return () => clearInterval(vcodeTimer)
    // }, [countDown]);

    // function blurPhone(e: string) {
    //     const mobPatt = new RegExp(/^1[3456789]\d{9}$/);
    //     const isMobTrue = mobPatt.test(phone);
    //     track(isWx ? 'MobileBlur_Wx' : 'MobileBlur', isMobTrue ? '手机号码验证通过' : '手机号码验证失败');
    //     console.log(isMobTrue);
    // }

    // function changePhone(e: string) {
    //     setPhone(e);
    //     setPhoneError('');
    //     setRegisterError('');
    // }

    // function changeVerCode(e: string) {
    //     setVerCode(e);
    //     setVerCodeError('');
    //     setRegisterError('');
    // }

    // const getVerCode = () => {
    //     const mobPatt = new RegExp(/^1[3456789]\d{9}$/);
    //     const isMobTrue = mobPatt.test(phone);
    //     // setPhoneError(!isMobTrue ? '请输入正确的手机号' : '');
    //     // if (!isMobTrue) {
    //     //     return;
    //     // }
    //     track(isWx ? 'GetVCode_Wx' : 'GetVCode', isMobTrue ? '手机号码验证通过' : '手机号码验证失败');

    //     if (!isMobTrue) {
    //         setToast({ show: true, text: '请输入正确的手机号' });
    //         return;
    //     }

    //     // setVerCodeDisabled(true);
    //     if (countDown > 0) {
    //         return;
    //     }
    //     postVerCode(downloadInfo.config.channelName, phone).then(res => {
    //         if (res.status === 200) {
    //             setCountDown(60);
    //             // setVerCodeDisabled(true);
    //             track(isWx ? 'GetVCode_Wx' : 'GetVCode', '获取验证码成功');

    //         } else {
    //             setRegisterError(res.msg);
    //             track(isWx ? 'GetVCode_Wx' : 'GetVCode', res.msg);
    //         }
    //     });
    // }@guohai ，下载地址，你替换成这个  http://a.app.qq.com/o/simple.jsp?pkgname=com.example.a14409.overtimerecord&android_schema=snmi%3A%2F%2Fcom.example.a14409.overtimerecord%2Fmain%2Faction

    const downloadApp = () => {
       // const isRedirectToMarket = parseInt(downloadInfo.config.redirectToMarket) === 1;

        track(isWx ? 'InvitePage_Download_Wechat' : 'InvitePage_Download_Browser', isWx ? '微信下载' : '浏览器下载');
        window.location.href = downloadInfo.config.downloadUrl;

        // if (isRedirectToMarket) {
        //     track(isWx ? 'InvitePage_Download_Wechat' : 'InvitePage_Download_Browser', isWx ? '微信下载' : '浏览器下载');

        //     if (isWx) {
        //         const ua = navigator.userAgent.toLocaleLowerCase();
        //         if (ua.indexOf('huawei') !== -1 || ua.indexOf('honor') !== -1) {
        //             window.location.href = "https://appgallery.huawei.com/#/app/C100341663?locale=zh_CN&source=appshare&subsource=C100341663&shareTo=weixin&shareFrom=appmarket";
        //         } else {
        //             setShowTips(true);
        //         }
        //     } else {
        //         window.location.href = "market://details?id=com.example.a14409.overtimerecord";
        //     }
        // } else {
        //     track(isWx ? 'InvitePage_Download_Wechat' : 'InvitePage_Download_Browser', isWx ? '微信下载_非市场跳转' : '浏览器下载_非市场跳转');

        //     window.location.href = "/api/app/index?cid=" + params.cid;//downloadInfo.config.downloadUrl + '?cid=' + params.cid;
        // }

        // window.location.href = downloadInfo.config.downloadUrl;
        // + '?cid=' + params.cid;

        // // 注册后会重新跳转这个页面，并带着注册用户Id参数，在加载时已经验证好了
        // if (downloadInfo.isRegisterInvitee) {
        //     //     setShowTips(true);
        //     // } else {
        //     //     window.location.href = downloadInfo.config.downloadUrl;
        //     // }
        //     track(isWx ? 'Download_Wx' : 'Download', '下载App');

        //     window.location.href = "/api/app/index?cid=" + params.cid;//downloadInfo.config.downloadUrl + '?cid=' + params.cid;
        //     return;
        // }

        // const mobPatt = new RegExp(/^1[3456789]\d{9}$/);
        // const isMobTrue = mobPatt.test(phone);
        // // setPhoneError(!isMobTrue ? '请输入正确的手机号' : '');
        // if (!isMobTrue) {
        //     setToast({ show: true, text: '请输入正确的手机号' });
        //     track(isWx ? 'RegisterVerify_Wx' : 'RegisterVerify', '手机号错误');
        //     return;
        // }

        // const codePatt = new RegExp(/^[1-9]|[1-9][0-9]*$/);
        // const isCodeTrue = codePatt.test(verCode);
        // // setVerCodeError(!isCodeTrue ? '请输入正确的验证码' : '');
        // if (!isCodeTrue) {
        //     setToast({ show: true, text: '请输入正确的验证码' });
        //     track(isWx ? 'RegisterVerify_Wx' : 'RegisterVerify', '验证码错误');
        //     return;
        // }

        // if (!isMobTrue || !isCodeTrue) {
        //     return;
        // }

        // console.log(downloadInfo.config.downloadUrl);

        // setIsLoading(true);
        // register({ channelId: params.cid, inviterId: params.arg || '', mobile: phone, code: verCode, appVersion: params.version }).then(res => {
        //     setIsLoading(false);
        //     if (res.status === 200) {
        //         // localStorage.setItem("registeruser", res.data);
        //         // props.history.push(`/download/${params.cid}/${params.arg}/${res.data}`);
        //         const newUrl = `${downloadInfo.config.shareWxUrl}/download/${params.cid}/${params.arg}/${params.version}/${res.data}`;
        //         window.location.href = newUrl;

        //         //     setShowTips(true);
        //         // } else {
        //         //     window.location.href = downloadInfo.config.downloadUrl;
        //         // }
        //     } else {
        //         // setRegisterError(res.msg);
        //         setToast({ show: true, text: res.msg });
        //         track('RegisterFail', res.msg);
        //     }
        // });

    }

    return (
        <div className="bg" style={{ height: '100%' }}>
            <div className="redpacket2">
                <div style={{ height: '80px', paddingTop: '30px' }}>
                    <Carousel withdrawList={withdrawList}></Carousel>
                </div>

                <RedPacketCoundown config={downloadInfo.config} endTime={downloadInfo.endTime}></RedPacketCoundown>

                <div style={{ fontSize: '16px', margin: '10px auto', textAlign: 'center', color: '#ffffff', fontWeight: 'bold' }}>{downloadInfo.config.channelName}</div>

                <CopyToClipboard text={'#sm ' + params.arg + '|' + params.cid + '|' + params.deviceId + '#'}>
                    <button type="button" onClick={downloadApp} className="btnDownload" style={{ display: downloadInfo.config.downloadUrl ? 'block' : 'none', marginTop: '55px' }}>
                        打开App领现金
                    </button>
                </CopyToClipboard>
            </div>
            <Loading show={isLoading} text={'数据加载中'}></Loading>
            <Toast show={toast.show} text={toast.text} delay={3000} switchShow={(e) => switchShowToast(e)}></Toast>

            <div className="downloadTips" style={{ display: showTips ? 'block' : 'none' }} onClick={() => setShowTips(false)}>
                <div className="tips"></div>
            </div>
        </div>
    );
}
export default TaskDownload;

{/* 
        const dwnProps = {
        "data_ue_src": "http://a.app.qq.com/o/simple.jsp?pkgname=com.example.a14409.overtimerecord"
    };
    <a href="http://a.app.qq.com/o/simple.jsp?pkgname=com.example.a14409.overtimerecord" target="_blank" {...dwnProps}>
测试下载
</a> 
            <button type="button" style={{border:'0',background:'none',color:"#ffa847",margin:'0 auto',textAlign:'center'}} onClick={testDownload}>邀请点击</button>

<div className="iptRegister" style={{ marginTop: '50px', display: !downloadInfo.isRegisterInvitee ? 'block' : 'none' }}>
    <div className="phone">
        <input type="text" placeholder="请输入手机号" name="phone" value={phone} onBlur={(e) => blurPhone(e.target.value)} onChange={(e) => changePhone(e.target.value)} />
        <div style={{ position: 'absolute', left: '45px', fontSize: '14px', bottom: '-3px', color: '#ff0000', fontWeight: 'normal' }}>{phoneError}</div>
    </div>
</div>
<div className="iptRegister" style={{ display: !downloadInfo.isRegisterInvitee ? 'block' : 'none' }}>
    <button type="button" onClick={getVerCode} style={{ width: '110px', float: "right" }}>{countDown <= 0 ? '获取验证码' : '（' + countDown + ' 秒）'}</button>
    <div className="pwd">
        <input type="text" placeholder="请输入验证码" name="verCode" value={verCode} onChange={(e) => changeVerCode(e.target.value)} />
        <div style={{ position: 'absolute', left: '45px', fontSize: '14px', bottom: '-3px', color: '#ff0000', fontWeight: 'normal' }}>{verCodeError}</div>
    </div>
</div>
<div style={{ color: '#ffffff', fontSize: '14px', height: '20px', lineHeight: '20px', textAlign: 'center' }}>{registerError}</div>
                <CopyToClipboard text={'#sm ' + params.arg + '|' + params.cid + '#'}>
                    <button type="button" onClick={downloadApp} className="btnDownload" style={{ display: downloadInfo.config.downloadUrl ? 'block' : 'none', marginTop: downloadInfo.isRegisterInvitee ? '35px' : '' }}>
                        {!downloadInfo.isRegisterInvitee ? '立即领现金' : '打开App领现金'}
                    </button>
                </CopyToClipboard>
*/}