import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import TaskInvite from './pages';
import TaskDownload from './pages/download';
import TaskWithdraws from './pages/withdraw';

const history = createBrowserHistory();

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/share/:cid/:uid?" exact component={TaskInvite} />
        <Route path="/download/:cid/:arg/:version?/:deviceId?" exact component={TaskDownload} />
        <Route path="/download/:cid/:arg?" exact component={TaskDownload} />
        <Route path="/withdraw/:cid/:uid?" exact component={TaskWithdraws} />
      </Switch>
    </Router>
  );
}

export default App;
