import React, { useEffect, useState } from 'react'
import { IWidthdrawItem } from '../models/shareapp';

export interface ICarouselProps {
    withdrawList: IWidthdrawItem[];
}

export function Carousel(props: ICarouselProps) {
    const [withdrawIndex, setWithdrawIndex] = useState(0);
    const [content, setContent] = useState('');

    let myInterval: any;

    function interval() {
        myInterval = setInterval(() => {
            if (props.withdrawList && props.withdrawList.length > 0) {
                if (withdrawIndex === props.withdrawList.length - 1) {
                    setWithdrawIndex(0);
                } else {
                    setWithdrawIndex(withdrawIndex + 1);
                }

                setContent('<div>' + props.withdrawList[withdrawIndex].desc + '成功提现' + props.withdrawList[withdrawIndex].money + '元</div>');

                // if (carouselPosY >= (props.withdrawList.length - 1) * 50) {
                //     setCarouselPosY(0);
                // } else {
                //     setCarouselPosY(carouselPosY + 50);
                // }
            }
        }, 2000)
    }

    useEffect(() => {
        interval();
        return () => clearInterval(myInterval)
    });

    return (
        <div className="carousel" dangerouslySetInnerHTML={{ __html: content }}></div>
    )
}