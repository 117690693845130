import React, { FC, useEffect, useRef, useState } from 'react';
import TaskInvRule from './rule';
import TaskPopWithdraw from './popwithdraw';
import TaskInvOffline from './offline';
import { getShareIndex, getTrack, getWithdrawInfo, getWithdrawList, TrackType } from '../services/invite';
import { IChannelUserId, IPopWidthdraw, IShareInfoWrapper, IWidthdrawItem, IWxInfo } from '../models/shareapp';
import Loading from './loading';
import Toast from './toast';
import { GenCountDown, GenWithdrawPage, RedPacketCoundown } from '../components/widget';
import { Carousel } from '../components/carousel';
import { appVersionName, getDeviceId, getSmUserId, isSMLogin } from '../utils/smapp';
import { isEmpty } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';

interface ITabConWithdrawProps {
    withdrawList: IWidthdrawItem[];
    hasMore: boolean;
    showMore: (key: any) => void;
    fold: (key: any) => void;
}

function TabConCashsHistory(props: { cashList: any[] }) {
    if (props.cashList.length === 0) {
        return <div className="withdraws" style={{ border: '0' }}><i style={{ textAlign: 'center', color: '#999999' }}>暂无现金记录哦～</i></div>;
    } else {
        return (
            <>
                {
                    props.cashList.map((t, i) => {
                        return <div className="withdraws" key={i}><i>{t.money}元</i><em>{t.status}</em></div>
                    })
                }
            </>
        );
    }
}

function TabConWithdraw(props: ITabConWithdrawProps) {
    if (props.withdrawList) {
        return (<>
            {
                props.withdrawList.map((t, i) => {
                    return (
                        <div key={i} className="withdraws">
                            <i>
                                <img src="https://cdnp.h5120.com/upload/task/invite/taskinv_rdb09155.png" alt="" width="20" />
                                <span className="f">+{t.money}</span>
                            </i>
                            <em>{t.desc}</em>
                        </div>
                    )
                })
            }

            <button type="button" className="more" onClick={props.showMore} style={{ display: props.hasMore ? 'block' : 'none' }}>
                展开更多
                <div className="downArrow"></div>
            </button>
            <button type="button" className="more" onClick={props.fold} style={{ display: props.hasMore ? 'none' : 'block' }}>
                折叠
                <div className="upArrow"></div>
            </button>
        </>
        );
    }
    return (<div>暂无记录
        <button type="button" className="more" onClick={props.showMore}>
            展开更多
                <div className="downArrow"></div>
        </button>
    </div>);
}

const TaskInvite = (props: any) => {
    const initShareInfo: IShareInfoWrapper = { myAmount: 0, cacheHistories: [], config: {} };
    const params: IChannelUserId = props.match.params;
    // const [isLogin, setIsLogin] = useState(false);

    const [shareInfo, setShareInfo] = useState(initShareInfo);
    const [toast, setToast] = useState({ show: false, text: '' });

    const [isLoading, setIsLoading] = useState(false);
    const [deviceId, setDeviceId] = useState('');
    const [appVersion, setAppVersion] = useState('');

    const intWidthItems: IWidthdrawItem[] = [];
    const [withdrawHistories, setWithdrawHistories] = useState(intWidthItems);

    const [withdrawList, setWithdrawList] = useState(intWidthItems);

    const [withdrawHistoriesFilter, setWithdrawHistoriesFilter] = useState({ index: 0, hasMore: true });
    const [tabIndex, switchTab] = useState(1);
    const [isShowRule, setShowRule] = useState(false);
    const [isShowPopWithdraw, setShowPopWithdraw] = useState(false);
    const [isShowOffline, setShowOffline] = useState(false);
    // const [isShowPopInvite, setShowPopInvite] = useState(false);

    const initPopWithdraw: IPopWidthdraw = { amount: 0, payAccount: '', trueName: '', isEnableWithdraw: false, config: {} };
    const [popWithdraw, setPopWithdraw] = useState(initPopWithdraw);

    function track(trackType: TrackType, lbl: string, oaid: string, version: string) {
        getTrack({
            userId: params.uid ? params.uid : '',
            channelId: params.cid,
            inviterId: oaid,
            trackType: trackType,//isWx ? 'DownloadIndex_Wx' : 'DownloadIndex',
            page: 'share',
            label: lbl,
            appVersion: version
        });
    }

    // function intervalIsLogin() {
    //     isLoginIntv = setInterval(() => {
    //         setIsLogin(isSMLogin());
    //     }, 2000)
    // }

    // useEffect(() => {
    //     intervalIsLogin();
    //     return () => clearInterval(isLoginIntv)
    // });

    useEffect(() => {
        // let cw = popCurrRef.current?.clientWidth || 0;
        // setIsLogin(true);
        console.log(11);

        let oaid = getDeviceId();
        const version = appVersionName();

        setAppVersion(version);

        if (!oaid || oaid.length < 5) {
            if (!isSMLogin()) {
                try {
                    sm.login();
                } catch (ex) {
                }
                //return;
            } else {
                oaid = 'u:' + getSmUserId(params.uid);
                setDeviceId(oaid);
            }
        } else {
            setDeviceId(oaid);
        }

        // const paramsId = params ? getSmUserId(params.uid) : '';
        // let obj = {
        //     title: "您的好友邀请您使用记加班",
        //     des: "现在下载，可参与领现金活动",
        //     linkurl: `${shareUrl}/download/${paramsId}`,
        //     img: `https://cdnp.h5120.com/upload/task/invite/shareicon.png`,
        // }
        // wx.shareWxMessage('https://share.campaign.h5abc.com' + match.url, obj);
        // getWithdrawTable();

        // setIsLogin(isSMLogin());
        setIsLoading(true);
        console.log(4444);

        getShareIndex({ id: params.uid, cid: params.cid, inviterId: oaid, appVersion: version }).then(res => {
            setIsLoading(false);
            if (res.status === 200) {
                setShareInfo(res.data);
            } else {
                setToast({ show: true, text: res.msg });
            }
        });

        getWithdrawList({ cid: params.cid, pageIndex: 0, pageSize: 20 }).then(t => {
            setWithdrawList(t.data);
        });

        requestWithdrawList(0);

        const shareIndexKey = "trackshareindex";
        if (oaid && oaid.length > 5) {
            // 用户第二次打开不计数
            if (localStorage.getItem(shareIndexKey) !== oaid) {
                track('ActivityPage_Load', '活动页加载', oaid, version);
                localStorage.setItem(shareIndexKey, oaid);
            } else {
                track('ActivityPage_Load', '活动页加载_重复', oaid, version);
            }
        } else {
            track('ActivityPage_Load', '活动页加载_OAID为空', oaid, version);
        }

    }, []);

    function changeTab(index: number) {
        switchTab(index);
    }

    function requestWithdrawList(idx: number) {
        getWithdrawList({ cid: params.cid, pageIndex: idx, pageSize: 4 }).then(response => {
            if (response && response.status === 200 && response.data) {
                const isArr = Array.isArray(response.data);
                if (isArr && response.data.length > 0) {
                    setWithdrawHistoriesFilter({ index: idx + 1, hasMore: true });
                    const wtlist: IWidthdrawItem[] = response.data;
                    setWithdrawHistories([...withdrawHistories, ...wtlist]);
                } else {
                    setWithdrawHistoriesFilter({ index: idx + 1, hasMore: false });
                }
            }
        });
    }

    const getWithdrawTable = () => {
        requestWithdrawList(withdrawHistoriesFilter.index);
    }

    const foldWithdraw = () => {
        setWithdrawHistoriesFilter({ index: 1, hasMore: true });
        setWithdrawHistories(withdrawHistories.slice(0, 4));
    }

    const switchShowRule = () => {
        setShowRule(!isShowRule);
        // getTrack({ userId: getSmUserId(params.uid), channelId: params.cid, trackType: 'ShowRule' });
        if (!isShowRule) {
            track('ActivityPage_Rule_Click', '活动规则按钮点击', deviceId, appVersion);
        }
    }

    const switchShowWithdraw = () => {
        if (!isShowPopWithdraw) {
            // try {
            //     const isLogin = isSMLogin();
            //     if (!isLogin) {
            //         track('ShowPay', '点击提现_未登录');
            //         sm.login();
            //         return;
            //     }
            // } catch (ex) {
            //     return;
            // }
            // 被邀请人浏览器页加载	

            setIsLoading(true);
            getWithdrawInfo({ oaid: deviceId, cid: params.cid }).then(t => {
                setIsLoading(false);
                if (t.status === 200) {
                    const dataResult: IPopWidthdraw = t.data;
                    setPopWithdraw(t.data);
                    track(dataResult.isEnableWithdraw ? 'ActivityPage_Draw_Enable' : 'ActivityPage_Draw_Unable',
                        dataResult.isEnableWithdraw ? '提现按钮点击_可以提现' : '提现按钮点击_不够提现', deviceId, appVersion);

                    track('ActivityPage_Draw_Click', '提现按钮点击', deviceId, appVersion);
                } else {
                    track('ActivityPage_Draw_Click', '提现按钮点击_' + t.msg, deviceId, appVersion);
                    setToast({ show: true, text: t.msg });
                }
            });
        } else {
            // 提现界面关闭的时候 刷新界面数据
            getShareIndex({ id: params.uid, cid: params.cid, inviterId: deviceId, appVersion: appVersion }).then(res => {
                if (res.status === 200) {
                    setShareInfo(res.data);
                }
            });

            track('DrawPage_Close', '提现页面关闭', deviceId, appVersion);
        }
        setShowPopWithdraw(!isShowPopWithdraw);
    }

    const switchShowOffline = () => {
        setShowOffline(!isShowOffline);
    }

    function shareWx(trackType: TrackType, desc: string) {
        const version = appVersion ? appVersion : '-';
        const oaid = deviceId ? deviceId : '-';
        const wx: IWxInfo = {
            title: shareInfo.config.shareWxTitle,
            des: shareInfo.config.shareWxDesc,
            linkurl: `${shareInfo.config.shareWxUrl}/download/${params.cid}/${params.uid}/${version}/${oaid}`,
            img: shareInfo.config.shareWxIcon
        };

        const activeStatus = shareInfo.config.activeStatus ? parseInt(shareInfo.config.activeStatus) : 0;
        if (activeStatus === 1) {
            track(trackType, desc, deviceId, appVersion);
            sm.wxShareHttp(wx.linkurl, wx.title, wx.des, wx.img);

            // try {
            //     if (!isSMLogin()) {
            //         track('ClickShare', '点击微信分享_未登录');
            //         sm.login();
            //     } else {
            //         track('ClickShare', '点击微信分享');
            //         sm.wxShareHttp(obj.linkurl, obj.title, obj.des, obj.img);
            //     }
            // } catch (ex) {
            //     // 老版本没有isLogin
            //     sm.wxShareHttp(obj.linkurl, obj.title, obj.des, obj.img);
            // }
        } else {
            setShowOffline(true);
        }
    }

    function switchShowToast(e: boolean) {
        setToast({ show: e, text: '' });
    }

    return (
        <div className="bg">
            <div className="redpacket">
                <div style={{ height: '45px', textAlign: 'right', padding: '0 5px' }}>
                    <button type="button" className="btnRule" onClick={switchShowRule}>活动规则</button>
                </div>
                <div style={{ height: '90px', position: 'relative' }}>
                    <div style={{ paddingTop: '20px' }}>
                        <Carousel withdrawList={withdrawList}></Carousel>
                    </div>
                    <button type="button" onClick={switchShowWithdraw} className="btnWithdraw" style={{ right: '13.4%', bottom: '-50px' }}>提现</button>
                </div>

                <div style={{ height: '210px', textAlign: 'center' }}>
                    <div style={{
                        width: '140px', height: '20px', lineHeight: '20px', fontSize: '16px', textAlign: 'center', fontWeight: 'bold',
                        color: '#FA662D', margin: '0 auto', position: 'relative'
                    }}>
                    </div>
                    <div style={{ height: '40px', lineHeight: '40px', color: '#666666', position: 'relative' }}>
                        我的现金（元）
                    </div>
                    <div style={{ height: '50px', lineHeight: '50px', fontSize: '50px', fontWeight: 'bold', color: '#FF4A03' }}>{shareInfo.myAmount}</div>
                    <div style={{ height: '40px', lineHeight: '40px', fontSize: '13px', color: '#666666' }}>{shareInfo.config.withdrawDesc}</div>
                </div>

                <div style={{ height: '50px', marginBottom: '15px', textAlign: 'center' }}>
                    <button type="button" onClick={() => shareWx('ActivityPage_Share_Click', '邀请按钮点击')} className="btnShare" style={{ display: shareInfo.config.activeStatus ? 'block' : 'none' }}><span>邀请好友领更多现金</span></button>
                </div>

                <div style={{ height: '60px', lineHeight: '22px', color: '#ffffff', textAlign: 'center', fontSize: '13px' }}
                    dangerouslySetInnerHTML={{ __html: shareInfo.config.shareAndWithdrawTips }}></div>

                <div className="invprocess">
                    <div className="title"></div>
                    <div className="content" dangerouslySetInnerHTML={{ __html: shareInfo.config.inviteProcess }}></div>
                </div>
            </div>

            <div className="tabPanel">
                <ul className="tabItems">
                    <li className={tabIndex === 0 ? 'active' : ''} onClick={() => changeTab(0)}>提现晒单</li>
                    <li className={tabIndex === 1 ? 'active' : ''} onClick={() => changeTab(1)}>提现攻略</li>
                    <li className={tabIndex === 2 ? 'active' : ''} onClick={() => changeTab(2)}>现金记录</li>
                </ul>
                <div className="content" style={{ display: tabIndex === 0 ? 'block' : 'none' }}>
                    <TabConWithdraw withdrawList={withdrawHistories} hasMore={withdrawHistoriesFilter.hasMore} showMore={getWithdrawTable} fold={foldWithdraw}></TabConWithdraw>
                </div>
                <div className="content" style={{ display: tabIndex === 1 ? 'block' : 'none', color: '#666666', paddingTop: '20px' }}>
                    <div className="list" dangerouslySetInnerHTML={{ __html: shareInfo.config.withdrawRule }}></div>
                </div>
                <div className="content" style={{ display: tabIndex === 2 ? 'block' : 'none' }}>
                    <TabConCashsHistory cashList={shareInfo.cacheHistories}></TabConCashsHistory>
                </div>

            </div>
            <div className="footerTips">
                <h4>郑重申明</h4>
                <div dangerouslySetInnerHTML={{ __html: shareInfo.config.declares }}></div>
                <img src="https://cdnp.h5120.com/upload/task/invite/taskinv09156.png" alt="" width="1" height="1" />
                <img src="https://cdnp.h5120.com/upload/task/invite/taskinv_pop09163.png" alt="" width="1" height="1" />
            </div>

            <span style={{ color: '#d85c30' }}>{deviceId}</span>

            <GenWithdrawPage show={isShowPopWithdraw} showBtnClose={true} switchShow={switchShowWithdraw} config={popWithdraw} shareWx={() => shareWx('DrawPage_Share_Click', '提现邀请按钮点击')}
                user={{ uid: params.uid, cid: params.cid, oaid: deviceId, version: appVersion }}></GenWithdrawPage>

            <TaskInvRule switchShow={switchShowRule} show={isShowRule} rules={shareInfo.config.activeRule}></TaskInvRule>

            <TaskInvOffline switchShow={switchShowOffline} show={isShowOffline} text={shareInfo.config.offLineNotice}></TaskInvOffline>
            <Loading show={isLoading} text={'数据加载中'}></Loading>
            <Toast show={toast.show} text={toast.text} delay={3000} switchShow={(e) => switchShowToast(e)}></Toast>

        </div>
    );
}

export default TaskInvite;
// export default connect(
//     ({ invite, loading }: { invite: InviteState; loading: Loading }) => ({
//         invite,
//         loading: loading,
//     }),
// )(TaskInvite);

{/*<TaskPopWithdraw switchShow={switchShowWithdraw} showBtnClose={true}
isEnoughMoney={checkIsEnoughMoney}
user={{ uid: params.uid, cid: params.cid, oaid: deviceId, version: appVersion }}
show={isShowPopWithdraw} info={popWithdraw}></TaskPopWithdraw>
<PopInvite switchShow={switchShowPopInvite} show={isShowPopInvite} text={shareInfo.myAmount}></PopInvite>

 <span style={{ display: loadingError ? 'block' : 'none', color: '#ffffff', fontSize: '16px' }}>{loadingError}</span> */ }
