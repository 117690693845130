import { getTrack, getWithdrawInfo, getWithdrawList, TrackType } from '../services/invite';
import React, { FC, useEffect, useState } from 'react';
import { IChannelUserId, IPopWidthdraw, IWidthdrawItem, IWxInfo } from '../models/shareapp';
import { appVersionName, getDeviceId } from '../utils/smapp';
import Toast from './toast';
import Loading from './loading';
import { Carousel } from '../components/carousel';
import { GenWithdrawPage } from '../components/widget';

// interface ISingleWithdrawProps {
//     isLogin: boolean;
//     info: IPopWidthdraw;
//     endTime?: string;
//     user: { uid?: string, cid: string, oaid: string };
//     switchShowWithdraw: (key: any) => void;
// }


const TaskWithdraws = (props: any) => {
    const initPopWithdraw: IPopWidthdraw = { amount: 0, payAccount: '', trueName: '', isEnableWithdraw: false, config: {} };
    const [config, setConfig] = useState(initPopWithdraw);
    const [isLoading, setIsLoading] = useState(false);
    const initWithdraws: IWidthdrawItem[] = [];
    const [withdrawList, setWithdrawList] = useState(initWithdraws);
    const [toast, setToast] = useState({ show: false, text: '' });
    const [deviceId, setDeviceId] = useState('');
    const [appVersion, setAppVersion] = useState('');
    // const [isShowPopWithdraw, setShowPopWithdraw] = useState(true);
    // const [isShowPopInvite, setShowPopInvite] = useState(false);

    const params: IChannelUserId = props.match.params;

    function track(trackType: TrackType, lbl: string, oaid: string, version: string) {
        getTrack({
            userId: params.uid ? params.uid : '',
            channelId: params.cid,
            inviterId: oaid,
            trackType: trackType,//isWx ? 'DownloadIndex_Wx' : 'DownloadIndex',
            page: 'withdraw',
            label: lbl,
            appVersion: version
        });
    }

    const loadtWithdrawInfo = (cid: string, oaid: string) => {
        setIsLoading(true);
        setConfig(initPopWithdraw);

        getWithdrawInfo({ cid: cid, oaid: oaid }).then(t => {
            setIsLoading(false);
            if (t.status === 200) {
                setConfig(t.data);
            } else {
                setToast({ show: true, text: t.msg });
            }
        });
    }

    useEffect(() => {
        // try {
        //     const isLogin = isSMLogin();
        //     setIsLogin(isLogin);

        //     if (!isLogin) {
        //         track('WithdrawIndex', '点击提现_未登录');
        //         sm.login();
        //     }
        // } catch (ex) {
        // }
        const oaid = getDeviceId();
        const version = appVersionName();
        setDeviceId(oaid);
        setAppVersion(version);

        const widthdrawIndexKey = "trackwithdrawindex";
        if (oaid && oaid.length > 5) {
            // 用户第二次打开不计数
            if (localStorage.getItem(widthdrawIndexKey) !== oaid) {
                track('DrawPage_Load', '提现页面加载', oaid, version);
                localStorage.setItem(widthdrawIndexKey, oaid);
            } else {
                track('DrawPage_Load', '提现页面加载_重复', oaid, version);
            }
        } else {
            track('DrawPage_Load', '提现页面加载_OAID为空', oaid, version);
        }

        loadtWithdrawInfo(params.cid, oaid);

        getWithdrawList({ cid: params.cid, pageIndex: 0, pageSize: 5 }).then(t => {
            setWithdrawList(t.data);
        });
    }, []);

    const switchShowWithdraw = () => {
        loadtWithdrawInfo(params.cid, deviceId);
    }

    function switchShowToast(e: boolean) {
        setToast({ show: e, text: '' });
    }

    const shareWx = () => {
        const wx:IWxInfo =  {
            title: config.config.shareWxTitle,
            des: config.config.shareWxDesc,
            linkurl: `${config.config.shareWxUrl}/download/${params.cid}/${params.uid}/${appVersion}/${deviceId}`,
            img: config.config.shareWxIcon
        }

        const activeStatus = config.config.activeStatus ? parseInt(config.config.activeStatus) : 0;
        if (activeStatus === 1) {
            track('DrawPage_Share_Click', '提现邀请按钮点击', deviceId, appVersion);
            sm.wxShareHttp(wx.linkurl, wx.title, wx.des, wx.img);

            // try {
            //     if (!isSMLogin()) {
            //         track('ClickShare', '点击微信分享_未登录');
            //         sm.login();
            //     } else {
            //         track('ClickShare', '点击微信分享');
            //         sm.wxShareHttp(obj.linkurl, obj.title, obj.des, obj.img);
            //     }
            // } catch (ex) {
            //     // 老版本没有isLogin
            //     sm.wxShareHttp(obj.linkurl, obj.title, obj.des, obj.img);
            // }
        }
    }

    // const shareAndClose = () => {
    //     switchShow(false);
    //     shareWx();
    // }


    // const switchShowPopInvite = (isCloseAndInvite: boolean) => {
    //     track('DrawPage_Share_Click', '邀请按钮点击', deviceId, appVersion);
    //     if (isCloseAndInvite) {
    //         const wx = shareWxInfo();
    //         sm.wxShareHttp(wx.linkurl, wx.title, wx.des, wx.img);
    //     }

    //     setShowPopInvite(!isShowPopInvite);
    //     setShowPopWithdraw(true);
    // }
    // const checkIsEnoughMoney = (isEnoughMoney: boolean) => {
    //     console.log(isEnoughMoney);
    //     if (!isEnoughMoney) {
    //         setShowPopInvite(true);
    //     }
    // }

    return (
        <div className="bg" style={{ height: '100%' }}>
            <div className="redpacket2">
                <div style={{ height: '80px', paddingTop: '30px' }}>
                    <Carousel withdrawList={withdrawList}></Carousel>
                </div>

                <GenWithdrawPage show={true} switchShow={switchShowWithdraw} showBtnClose={false} shareWx={shareWx}
                config={config} user={{ uid: params.uid, cid: params.cid, oaid: deviceId, version: appVersion }}></GenWithdrawPage>
            </div>

            <Toast show={toast.show} text={toast.text} delay={3000} switchShow={(e) => switchShowToast(e)}></Toast>
            <Loading show={isLoading} text={'数据加载中'}></Loading>

        </div>
    );
}

export default TaskWithdraws;

{/* <TaskPopWithdraw user={{ uid: params.uid, cid: params.cid, oaid: deviceId, version: appVersion }}
isEnoughMoney={checkIsEnoughMoney}
switchShow={switchShowWithdraw} showBtnClose={false} show={isShowPopWithdraw} info={config}></TaskPopWithdraw>
<PopInvite switchShow={switchShowPopInvite} show={isShowPopInvite} text={config.amount}></PopInvite> */}