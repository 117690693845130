import React, { FC, useState } from 'react';

interface ITaskRuleProps {
    rules: string;
    show: boolean; // namespace
    switchShow: (key: any) => void;
}

const TaskInvRule: FC<ITaskRuleProps> = ({ show, switchShow, rules }) => {
    const availHeight = window.screen.availHeight;

    return (
        <div className="popbox" style={{ display: show ? 'block' : 'none' }}>
            <div className="wrapper" style={{ height: '555px', margin: availHeight < 600 ? '5px auto 0 auto' : '50px auto 0 auto' }}>
                <div className="top2"><h3 style={{width:'100%', height:'22px',lineHeight:'22px',position:'absolute',bottom:'0',margin:'0',padding:'0'}}>邀请好友</h3></div>
                <div className="content">
                    <div className="list" dangerouslySetInnerHTML={{ __html: rules }}></div>
                    <button type="button" className="btnpost" onClick={switchShow}>我知道了</button>
                </div>
            </div>
        </div>
    );
}

export default TaskInvRule;
