import React from 'react'

interface ITsExampleProps {
    endTime: string;
}

interface IExampleState {
    day: string;
    hour: string;
    minute: string;
    second: string;
}

export default class CountDown extends React.Component<ITsExampleProps, IExampleState> {
    timer: any;
    constructor(props: any) {
        super(props);
        this.state = {
            day: "0",
            hour: "0",
            minute: "0",
            second: "0",
        }
    }
    componentDidMount() {
        if (this.props.endTime) {
            let endTime = this.props.endTime.replace(/-/g, "/");
            this.countFun(endTime);
        }
    }
    //组件卸载取消倒计时
    componentWillUnmount() {
        clearInterval(this.timer);
    }

    countFun = (time: any) => {
        let end_time = new Date(time).getTime(),
            sys_second = (end_time - new Date().getTime());
        this.timer = setInterval(() => {
            //防止倒计时出现负数
            if (sys_second > 1000) {
                sys_second -= 1000;
                let day = Math.floor((sys_second / 1000 / 3600) / 24);
                let hour = Math.floor((sys_second / 1000 / 3600) % 24);
                let minute = Math.floor((sys_second / 1000 / 60) % 60);
                let second = Math.floor(sys_second / 1000 % 60);
                this.setState({
                    day: day.toString(),
                    hour: hour < 10 ? "0" + hour : hour.toString(),
                    minute: minute < 10 ? "0" + minute : minute.toString(),
                    second: second < 10 ? "0" + second : second.toString()
                })
            } else {
                clearInterval(this.timer);
                //倒计时结束时触发父组件的方法
                //this.props.timeEnd();
            }
        }, 1000);
    }
    render() {
        return (
            <ul className="times">
                <li>{this.state.hour}</li>
                <li>时</li>
                <li>{this.state.minute}</li>
                <li>分</li>
                <li>{this.state.second}</li>
                <li>秒</li>
            </ul>
        )
    }
}
