import { isEmpty, isUndefined } from 'lodash';
import service from '../utils/request';
import { IApiResult } from './services';

interface IInvitePayParams {
    id?: string; // userid
    cid: string;// channelid
}

interface InviteRegisterModel {
    channelId: string;
    inviterId: string;
    mobile: string;
    code: string;
    appVersion: string;
}

interface IPayParams {
    account: string;
    trueName: string;
    userId?: string;
    channelId: string;
    token: string;
    withdraw: number;
    inviterId: string;
}

export type TrackType =
    // 'ShareIndex' |
    // 'ClickShare' |
    // 'DownloadIndex' |
    // 'DownloadIndex_Wx' |
    // 'Download' |
    // 'Download_Wx' |
    // 'ShowPay' |
    // 'ShowRule' |
    // 'ShareAndInstall' |
    // 'RegisterSuccess' |
    // 'RegisterFail' |
    // 'RegisterAndLogin' |
    // 'MobileBlur_Wx' |
    // 'MobileBlur' |
    // 'GetVCode_Wx' |
    // 'GetVCode' |
    // 'RegisterVerify_Wx' |
    // 'RegisterVerify' |
    // 'WithdrawIndex' |
    // 'WithdrawBox'
    'ActivityPage_Draw_Enable'|
    'ActivityPage_Draw_Unable'|
    'DrawPage_Share_Click'|
    'ActivityPage_Load' |
    'ActivityPage_Share_Click' |
    'ActivityPage_Customer_Click' |
    'ActivityPage_Draw_Click' |
    'ActivityPage_Rule_Click' |
    'ActivityPage_AlipayID_Input' |
    'ActivityPage_AlipayName_Input' |
    'ActivityPage_DrawApply_Btn_Click' |
    'DrawPage_Close' |
    'InvitePage_Load_Wechat' |
    'InvitePage_Load_Browser' |
    'DrawPage_Load' |
    'InvitePage_Download_Wechat' |
    'InvitePage_Download_Browser';

interface IInviteTrackParams {
    userId?: string;
    channelId: string;
    trackType: TrackType;
}

interface IWxShareParams {
    shareUrl: string;
}

interface IWithdrawListParams {
    pageIndex: number;
    pageSize: number;
    cid?: string;
}

export async function aliPay(params: IInvitePayParams) {
    return service.post('/invite/AliPay', {
        data: params,
    });
}

export async function getWxConfig(params: IWxShareParams) {
    return service.post('/invite/WxShare', {
        data: params,
    });
}

export async function getWithdrawList(params: IWithdrawListParams): Promise<IApiResult> {
    return service.get(`/invite/WithdrawList?cid=${params.cid}&pageIndex=${params.pageIndex}&pageSize=${params.pageSize}`);
}

export async function getActiveInfo(params: { id: string, cid: string, inviteeId?: string }): Promise<IApiResult> {
    return service.get(`/invite/ActiveInfo?uid=${params.id}&cid=${params.cid}&inviteeId=${params.inviteeId}`);
}

export async function getShareIndex(params: { id?: string, cid: string, inviterId: string, appVersion: string }): Promise<IApiResult> {
    const uid = params.id ? params.id : '';
    return service.get(`/invite/ShareIndex?uid=${uid}&cid=${params.cid}&inviterId=${params.inviterId}&appVersion=${params.appVersion}`);
}

export async function getTrack(params: { inviteeId?: string, inviterId?: string, userId?: string, channelId: string, page: string, trackType: TrackType, label?: string, appVersion?: string }): Promise<IApiResult> {
    const userId = params.userId ? params.userId : '';
    const inviteeId = params.inviteeId ? params.inviteeId : '';
    const inviterId = params.inviterId ? params.inviterId : '';
    const label = params.label ? params.label : '';
    console.log(inviterId);

    try {
        let cnzzver = '';
        if (!isEmpty(params.appVersion) && !isUndefined(params.appVersion)) {
            cnzzver = params.appVersion;
        }
        window._czc.push(['_trackEvent', params.page + cnzzver + '_' + params.channelId + '_' + inviterId, params.trackType, label, '', '']);
    } catch (e) {
    }
    return service.get(`/invite/Track?inviteeId=${inviteeId}&inviterId=${inviterId}&userId=${userId}&channelId=${params.channelId}&trackType=${params.trackType}&appVersion=${params.appVersion}&detail=${label}`);
    // return Promise.reject('');
}

export async function getWithdrawInfo(params: { oaid: string, cid: string }): Promise<IApiResult> {
    return service.get(`/invite/WithdrawIndex?oaid=${params.oaid}&cid=${params.cid}`);
}

export async function postPay(params: IPayParams): Promise<IApiResult> {
    return service.post(`/invite/PayAction`, params);
}

export async function postVerCode(appName: string, mobile: string): Promise<IApiResult> {
    return service.post(`/invite/GetVerCode`, { appName, mobile });
}

export async function register(model: InviteRegisterModel): Promise<IApiResult> {
    return service.post(`/invite/Register`, model);
}

export async function isInviteeRegister(id: string): Promise<IApiResult> {
    return service.get(`/invite/IsRegister?uid=${id}`);
}

