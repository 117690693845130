import React, { FC, useEffect, useState } from 'react';
import { IPopWithdrawPage } from '../models/shareapp';
import PopInvite from '../pages/popinvite';
import TaskPopWithdraw from '../pages/popwithdraw';
import CountDown from "./countDown"

export interface ICountDownProps {
    endTime?: string;
}

export interface IRedPacketCoundownProps {
    endTime?: string;
    config: { [key: string]: string };
}

export function GenCountDown(props: ICountDownProps) {
    if (props.endTime) {
        return (<CountDown endTime={props.endTime}> </CountDown>);
    } else {
        return <></>
    }
}

export function RedPacketCoundown(props: IRedPacketCoundownProps) {
    return (
        <>
            <div style={{ height: '160px', textAlign: 'center' }}>
                <div style={{ width: '150px', height: '60px', lineHeight: '30px', color: '#FA662D', margin: '5px auto 0 auto', position: 'relative' }}>
                    <div style={{ width: '25px', height: '1px', backgroundColor: '#FA672D', fontSize: '1px', position: 'absolute', left: 0, top: '13px' }}></div>
                    <div style={{ width: '25px', height: '1px', backgroundColor: '#FA672D', fontSize: '1px', position: 'absolute', right: 0, top: '13px' }}></div>

                    <strong style={{ color: '#FA662C', display: 'block', fontSize: '16px' }}>新人奖励</strong>
                    <span>最高可领取现金（元）</span>
                </div>
                <div style={{ height: '50px', lineHeight: '50px', fontSize: '50px', fontWeight: 'bold', color: '#FF4A03' }}>{props.config.landingpageMoney}</div>
            </div>
            <div style={{ width: '60%', height: '90px', textAlign: 'center', color: '#ffffff', margin: '0 auto', paddingLeft: '10px' }}>
                <div style={{ height: '30px', lineHeight: '30px', marginBottom: '10px', textAlign: 'left', display: props.config.downloadUrl ? 'block' : 'none' }}>即将过期</div>
                <GenCountDown endTime={props.endTime}></GenCountDown>
            </div>
        </>
    );
}

export function GenWithdrawPage(props: IPopWithdrawPage) {
    function switchShowPopInvite() {
    }
    function checkIsEnoughMoney() {
    }

    if (!props.show) {
        return <></>;
    }

    if (props.config.isEnableWithdraw) {
        return (
            <TaskPopWithdraw user={props.user}
                isEnoughMoney={checkIsEnoughMoney}
                switchShow={props.switchShow} showBtnClose={props.showBtnClose} show={true} info={props.config}></TaskPopWithdraw>
        );
    } else {
        return (
            <PopInvite switchShow={props.switchShow} shareWx={props.shareWx} showBtnClose={props.showBtnClose} user={props.user} show={true} info={props.config}></PopInvite>
        );
    }
}
