import React, { FC, useEffect, useRef, useState } from 'react';
import { IPopWidthdraw } from '../models/shareapp';
import { getTrack, postPay, TrackType } from '../services/invite';

interface IPopWithdrawProps {
    info: IPopWidthdraw;
    user: { uid?: string, cid: string, oaid: string, version: string };
    show: boolean;
    showBtnClose: boolean;
    switchShow: (key: any) => void;
    isEnoughMoney: (isEnoughMoney: boolean) => void;
}

interface IPayAccount {
    name: string;
    value: string;
    hint: string;
    error: string;
    changeValue: (val: string) => void;
    blur: (val: string) => void;
}

function PayInput(props: IPayAccount) {
    return (
        <div style={{ width: 'auto', height: '50px', backgroundColor: '#f4f4f4', border: '0', borderRadius: '10px', padding: '0 15px', position: "relative", margin: '0 0 10px 0' }}>
            <button type="button" onClick={() => props.changeValue('')} style={{
                width: '25px', height: '25px', background: 'none', border: 0,
                display: props.value && props.value.length > 0 ? 'block' : 'none',
                position: 'absolute', right: '15px', top: '15px', outline: '0', margin: '0', padding: '0', zIndex: 1
            }}>
                <img width="100%" src="https://cdnp.h5120.com/upload/task/invite/taskinv_wd09156.png" alt="" />
            </button>
            <input type="text" placeholder={props.hint} name={props.name} value={props.value ? props.value : ''} onBlur={(e) => props.blur(e.target.value)}
                onChange={(e) => props.changeValue(e.target.value)} style={{ width: '100%', height: '46px', backgroundColor: '#f4f4f4', border: '0', borderRadius: '10px', outline: 'none' }} />
            <div style={{ position: 'absolute', left: '15px', bottom: '-3px', color: '#ff0000' }}>{props.error}</div>
        </div>
    );
}

const TaskPopWithdraw: FC<IPopWithdrawProps> = ({ show, switchShow, isEnoughMoney, info, showBtnClose, user }) => {
    const [account, setAccount] = useState('');
    const [trueName, setTrueName] = useState('');
    // const [isWithdrawError, setIsWithdrawError] = useState(false);
    const [accountErr, setAccountErr] = useState('');
    const [trueNameErr, setTrueNameErr] = useState('');
    const [netError, setNetError] = useState('');

    const [isWithdrawFinished, setWithdrawFinished] = useState(false);
    const [isBtnDisabled, setBtnDisabled] = useState(false);

    const [popStatus, setPopStatus] = useState({ text: '加载中...', display: true });

    const availHeight = window.screen.availHeight;

    // useEffect(() => {
    //     setShowWithdrawBg(show);
    // }, [show]);

    // const [withdrawLimit, setWithdrawLimit] = useState(0);

    // const [btnStatus, setbtnStatus] = useState('提现');

    // const [diff, setDiff] = useState(-1);
    // const [process, setProcess] = useState('0%');
    // const [tipPos, setTipPos] = useState({ left: '0', right: '0' });
    // const [arrowPos, setArrowPos] = useState({ left: '', right: '0' });

    let processCurrRef = useRef<HTMLDivElement>(null);
    let processAllRef = useRef<HTMLDivElement>(null);

    function track(trackType: TrackType, lbl: string) {
        getTrack({
            userId: user.uid ? user.uid : '',
            channelId: user.cid,
            inviterId: user.oaid,
            trackType: trackType,//isWx ? 'DownloadIndex_Wx' : 'DownloadIndex',
            page: 'withdraw',
            label: lbl,
            appVersion: user.version
        });
    }

    useEffect(() => {
        // const _limit = parseInt(info.config.withdrawLimit);
        // const _diff = info.amount >= _limit ? 0 : _limit - info.amount;
        // console.log(info.config);
        // console.log(info.config.withdrawLimit, info.amount, _limit, _diff);
        // setDiff(_diff);
        // setProcess(_limit > 0 ? ((_limit - _diff) / _limit) * 100 + '%' : '0%');

        // setWithdrawLimit(_limit);
        setAccount(info.payAccount);
        setTrueName(info.trueName);
    }, [info]);

    // useEffect(() => {
    //     let cw = processCurrRef.current?.clientWidth || 0;
    //     let totalWidth = processAllRef.current?.clientWidth || 0;
    //     const tipsWidth = 80;
    //     const offset = 7;

    //     if (cw > offset) {
    //         if (cw < tipsWidth) {
    //             setTipPos({ left: '0', right: '' });
    //         } else {
    //             if ((totalWidth - cw) > offset) {
    //                 setTipPos({ left: '', right: '-' + offset + 'px' });
    //             } else {
    //                 setTipPos({ left: '', right: '0px' });
    //             }
    //         }
    //         setArrowPos({ left: '', right: '-' + offset + 'px' });
    //     } else {
    //         setTipPos({ left: '0', right: '' });
    //         setArrowPos({ left: '0', right: '' });
    //     }
    // }, [process]);

    const changeAccount = (val: string) => {
        setAccountErr('');
        setNetError('');
        // setIsWithdrawError(false);

        setAccount(val);
    }
    const changeTrueName = (val: string) => {
        setTrueNameErr('');
        setNetError('');
        // setIsWithdrawError(false);

        setTrueName(val);
    }

    const blurAcccount = (val: string) => {
        // const mailPatt = new RegExp(/^[0-9A-Za-z][\.-_0-9A-Za-z]*@[0-9A-Za-z]+(?:\.[A-Za-z]+)+$/); // (/[\d\w\._-]+@[\d\w]+\.[\w]+/);
        // const isMailTrue = mailPatt.test(val);

        // const mobPatt = new RegExp(/^1[3456789]\d{9}$/);
        // const isMobTrue = mobPatt.test(val);

        track('ActivityPage_AlipayID_Input', (val && val.length > 5) ? '支付宝账号输入_验证成功' : '支付宝账号输入_' + val);
    }

    const blurTrueName = (val: string) => {
        const patt = new RegExp(/^[\u4E00-\u9FA5\uf900-\ufa2d·s\-]{2,20}$/);
        const isNameTrue = patt.test(val);

        track('ActivityPage_AlipayName_Input', isNameTrue ? '支付宝姓名输入_验证成功' : '支付宝姓名输入_' + val);
    }

    const startPay = () => {
        // if (diff === 0) {
        // const mailPatt = new RegExp(/^[0-9A-Za-z][\.-_0-9A-Za-z]*@[0-9A-Za-z]+(?:\.[A-Za-z]+)+$/); // (/[\d\w\._-]+@[\d\w]+\.[\w]+/);
        // const isMailTrue = mailPatt.test(account);

        // const mobPatt = new RegExp(/^1[3456789]\d{9}$/);
        // const isMobTrue = mobPatt.test(account);
        const isAccount = (account && account.length > 5);

        const patt = new RegExp(/^[\u4E00-\u9FA5\uf900-\ufa2d·s\-]{2,20}$/);
        const isNameTrue = patt.test(trueName);

        setAccountErr(isAccount ? '' : '请输入正确账号');
        setTrueNameErr(isNameTrue ? '' : '请输入真实姓名');
        //if (!(isMailTrue || isMobTrue)) {
        if (!isAccount) {
            // setIsWithdrawError(true);
            track('ActivityPage_DrawApply_Btn_Click', '申请提现按钮点击_支付宝账号验证失败_' + account);
            return;
        } else if (!isNameTrue) {
            // setIsWithdrawError(true);
            track('ActivityPage_DrawApply_Btn_Click', '申请提现按钮点击_支付宝姓名验证失败_' + trueName);
            return;
        }

        // if (!account || account.trim().length === 0) {
        //     // setIsWithdrawError(true);
        //     return;
        // } else if (!trueName || trueName.trim().length === 0) {
        //     // setIsWithdrawError(true);
        //     return;
        // }

        setBtnDisabled(true);
        postPay({
            channelId: user.cid,
            userId: user.uid,
            account: account,
            trueName: trueName,
            token: '',
            withdraw: 0, // 一次提走全部
            inviterId: user.oaid
        }).then(t => {
            if (t.status === 400) {
                // setbtnStatus("请检查账号与真实姓名");
                setNetError(t.msg);
                track('ActivityPage_DrawApply_Btn_Click', '申请提现按钮点击_' + t.msg);

                // setIsWithdrawError(true);
            } else if (t.status === 4699) {
                closePopbox();
            } else {
                setWithdrawFinished(true);

                setPopStatus({ display: false, text: '您已申请提现，请耐心等待' });
                track('ActivityPage_DrawApply_Btn_Click', '申请提现按钮点击_提现成功');

                closePopbox();

                // setTimeout(() => {
                //     setPopStatus({ display: true, text: '加载中...' });
                // }, 3000);
            }

            setBtnDisabled(false);

        });
    }

    const closePopbox = () => {
        setNetError('');
        setAccountErr('');
        setTrueNameErr('');
        switchShow(false);
        setWithdrawFinished(false);
        setBtnDisabled(false);
    }

    return (
        <div className="popbox" style={{ display: show ? 'block' : 'none' }}>
            <div className="tips" style={{ display: popStatus.display ? 'none' : 'block' }}>{popStatus.text}</div>
            <div className="wrapper" style={{ display: popStatus.display ? 'block' : 'none', height: '550px', margin: availHeight < 600 ? '5px auto 0 auto' : '50px auto 0 auto' }}>
                <div className="top1">
                    <div className="close" style={{ display: showBtnClose ? 'block' : 'none' }} onClick={closePopbox}></div>
                </div>
                <div className="content">

                    <div style={{ height: '260px', lineHeight: '180px', textAlign: 'center', fontSize: '16px', display: isWithdrawFinished ? 'block' : 'none' }}>您已申请提现，请耐心等待</div>

                    <div style={{ height: '280px', display: isWithdrawFinished ? 'none' : 'block' }}>
                        <div style={{ textAlign: 'center', color: '#FF4A03', fontSize: '18px', height: '25px', lineHeight: '25px' }}>可提现金额：{info.amount} 元</div>
                        <div style={{ color: '#ff0000', paddingLeft: '5px', height: '20px', lineHeight: '20px', marginTop: '-5px', textAlign: 'center' }}>{netError}</div>
                        <PayInput hint="请输入支付宝账号" name="account" value={account} error={accountErr} changeValue={changeAccount} blur={blurAcccount}></PayInput>
                        <PayInput hint="请输入真实姓名" name="trueName" value={trueName} error={trueNameErr} changeValue={changeTrueName} blur={blurTrueName}></PayInput>

                        <div style={{ color: '#999999', paddingLeft: '5px', lineHeight: '20px' }} dangerouslySetInnerHTML={{ __html: info.config.withdrawTips }}></div>
                        <button type="button" className={'btnpostEnable'} disabled={isBtnDisabled} onClick={startPay}>提现</button>
                    </div>

                    <p style={{ margin: '0', padding: '10px 0 5px 0', color: '#FE6506', textAlign: 'center' }}>{info.config.withdrawDesc}</p>
                    <p style={{ margin: '0', padding: '0', color: '#9A9A9A', textAlign: 'center' }}>{info.config.contact}</p>
                </div>
            </div>
        </div>
    );
}

export default TaskPopWithdraw;

// disabled={diff > 0 || isBtnDisabled}

{/* <div ref={processAllRef} style={{ width: '100%', height: '5px', backgroundColor: '#f4f4f4', borderRadius: '10px', position: 'relative', margin: '30px 0 15px 0', display: 'none' }}>
<div ref={processCurrRef} style={{ width: process, height: '5px', backgroundColor: '#ffd200', borderRadius: '10px', position: 'relative' }}>
    <div className="dd" style={tipPos}>仅差{diff ? diff : 0}元
    </div>
    <div className="process-arrow" style={arrowPos}></div>
</div>
</div> */}