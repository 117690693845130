export function isSMLogin(): boolean {
    try {
        return sm.isLogin();
    } catch (ex) {
        return false;
    }
}
export function getSmUserId(uid?: string) {
    if (!uid || uid.length < 5) {
        try {
            return sm.userValue('userId');
        } catch (ex) {
            return '';
        }
    } else {
        return uid;
    }
}
export function appVersionName(): string {
    try {
        return sm.appVersionName();
    } catch (ex) {
        return '';
    }
}
export function getDeviceId(): string {
    try {
        return sm.getDinfo();
    } catch (ex) {
        return '';
    }
}