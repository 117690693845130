import React, { FC, useState } from 'react';
import { IPopWidthdraw, IWxInfo } from '../models/shareapp';
import { getTrack, TrackType } from '../services/invite';

interface ITaskOfflineProps {
    show: boolean; // namespace
    user: { uid?: string, cid: string, oaid: string, version: string };
    info: IPopWidthdraw;
    showBtnClose: boolean;
    switchShow: (isCloseAndInvite: boolean) => void;
    shareWx: () => void;
}

const PopInvite: FC<ITaskOfflineProps> = ({ show, switchShow, user, info, showBtnClose,shareWx }) => {

    // const shareWxInfo = (): IWxInfo => {

    //     return {
    //         title: info.config.shareWxTitle,
    //         des: info.config.shareWxDesc,
    //         linkurl: `${info.config.shareWxUrl}/download/${user.cid}/${user.uid}/${user.version}/${user.oaid}`,
    //         img: info.config.shareWxIcon
    //     };
    // }

    // const shareWx = () => {
    //     const wx = shareWxInfo();

    //     const activeStatus = info.config.activeStatus ? parseInt(info.config.activeStatus) : 0;
    //     if (activeStatus === 1) {
    //         // track('ActivityPage_Share_Click', '邀请按钮点击', user.oaid, user.version);
    //         sm.wxShareHttp(wx.linkurl, wx.title, wx.des, wx.img);

    //         // try {
    //         //     if (!isSMLogin()) {
    //         //         track('ClickShare', '点击微信分享_未登录');
    //         //         sm.login();
    //         //     } else {
    //         //         track('ClickShare', '点击微信分享');
    //         //         sm.wxShareHttp(obj.linkurl, obj.title, obj.des, obj.img);
    //         //     }
    //         // } catch (ex) {
    //         //     // 老版本没有isLogin
    //         //     sm.wxShareHttp(obj.linkurl, obj.title, obj.des, obj.img);
    //         // }
    //     }
    // }

    // const shareAndClose = () => {
    //     switchShow(false);
    //     shareWx();
    // }

    return (
        <div className="popbox" style={{ display: show ? 'block' : 'none' }}>
            <div className="wrapper" style={{ height: '360px', margin: '90px auto 0 auto' }}>
                <div className="top3">
                    <span>提现</span>
                    <div className="close" style={{ top: '-40px', display: showBtnClose ? 'block' : 'none' }} onClick={() => switchShow(false)}></div>
                </div>
                <div className="content" style={{ height: '320px' }}>
                    <div style={{
                        height: '50px', textAlign: 'center', paddingTop: '20px',
                        lineHeight: '50px', fontSize: '50px', fontWeight: 'bold', color: '#FF4A03'
                    }}>{info.amount}</div>
                    <div style={{
                        width: '100px', height: '40px', textAlign: 'center', lineHeight: '40px',
                        borderBottom: '#cccccc 1px solid', fontSize: '14px', color: '#555555', margin: '0 auto'
                    }}>当前金额（元）</div>
                    <div style={{ height: '40px', lineHeight: '40px', padding: '10px 0 0 10px', color: '#555555' }}>支付宝提现</div>
                    <ul className="invpopMoneys">
                        <li><span>2元</span></li>
                        <li><span>10元</span></li>
                        <li><span>20元</span></li>
                    </ul>
                    <div style={{ height: '40px', lineHeight: '40px', textAlign: 'center', color: '#999999', paddingTop: '15px' }}>
                        <span>只要再邀请1人就能提现啦！</span>
                    </div>

                    <button type="button" className="btninvite" onClick={shareWx}>马上邀请好友赚钱</button>
                </div>
            </div>
        </div>
    );
}

export default PopInvite;
